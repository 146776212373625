import { required, requiredIf, minValue, minLength, helpers } from 'vuelidate/lib/validators';
import {
  isElementComparaisonTransaction,
  isContratTypeVisiteInterieurEtExterieur,
  isContratTypeVisiteSurPieces,
  isDistributionInterieureEtageTypeAutre,
  isDistributionInterieureSurfaceTypeAutre,
  isPrestationsSurfaceAnnexeTypeAutre,
  isEvaluationMethodeRetenueComparaisonEtRendement,
  isPrestationsDependanceTypeAutre,
  isSourceDesSurfacesActeNotarie,
  isContratConditionOccupationOccupe,
  isContratTypePrestationNotCertificatExpertise,
  hasValeurApresTravaux,
  hasValeurLocative,
  hasOptionFaciliteDeNegociationESFI,
  areBusinessEntryRelationsValid,
  isRapportVersionGreaterOrEqualsTo,
  isBusinessEntryDPEValid,
  isEtatJuridiquePrixOk,
  hasScenarioRenovation,
} from '@/helpers/rapport-expertise.helper';

import {
  isTypeDeBienTerrain,
  isTypeDeBienAppartement,
  isTypeDeBienMaison,
  hasFourchetteValeur,
  hasValeurVenteForcee,
  hasValeurVenteRapide,
} from '@/helpers/prestation-expertise.helper';
import { ContratTypePrestation } from '@/shared/enums/contrat-type-prestation.enum';
import { isNullOrEmpty } from '@/shared/utilities/string.utility';
import { ContratHypotheseEvaluationEnum } from '@/shared/enums/contrat-hypothese-evaluation.enum';

// custom validator qui fait qu'un champs est requis si la fonction en paramètre renvoie true quand on lui passe le parentVm
// par exemple, la déclaration suivante va exécuter isPrestationsSurfaceAnnexeTypeAutre pour tester si le type de surface est 'autre' et que le champs est requis:
// required: requiredIfTrueForParentVm(isPrestationsSurfaceAnnexeTypeAutre)
const requiredIfTrueForParentVm = (testFunction) =>
  helpers.withParams({ type: 'requiredIfTrueForParentVm', testFunction }, function (value, parentVm) {
    return testFunction(parentVm) ? helpers.req(value) : true;
  });

const requiredIfTrueForRapport = (testFunction) =>
  helpers.withParams({ type: 'requiredIfTrueForRapport', testFunction }, function (value) {
    return testFunction(this.mission.prestationExpertise) ? helpers.req(value) : true;
  });

const requiredPhotos = helpers.withParams({ type: 'requiredPhotos' }, function (value) {
  return this.mission.prestationExpertise.proprietes.photosObligatoires ? helpers.req(value) : true;
});

const isNotCertificatExpertise = function (rapport) {
  return isContratTypePrestationNotCertificatExpertise(rapport.contratTypePrestationId);
};

const requireIfCertificatExpertise = function (rapport) {
  return rapport.contratTypePrestationId === ContratTypePrestation.CertificatExpertise;
};

const requireIfRapportExpertise = function (rapport) {
  return rapport.contratTypePrestationId === ContratTypePrestation.RapportExpertise;
};

const requireIfVersion = function (version, actualVersion, isLessThan = false) {
  if (isLessThan) {
    return isNullOrEmpty(version) || version <= actualVersion;
  } else {
    return version >= actualVersion;
  }
};

// Validation pour pouvoir déposer le rapport
const rapportExpertiseValidation = {
  mission: {
    dateVisite: {
      required,
    },
    prestationExpertise: {
      nbKmEffectues: {
        required,
      },
      contratVisitePresenceContact: {
        requiredIf: requiredIf((rapport) => isContratTypeVisiteInterieurEtExterieur(rapport.contratTypeVisiteId)),
      },
      contratVisitePresenceQualificatifId: {
        required: requiredIf((rapport) => isContratTypeVisiteInterieurEtExterieur(rapport.contratTypeVisiteId)),
      },
      contratVisitePresenceCiviliteId: {
        required: requiredIf((rapport) => isContratTypeVisiteInterieurEtExterieur(rapport.contratTypeVisiteId)),
      },
      contratTypeVisiteId: {
        required,
      },
      contratCategorieDActifId: { required },
      contratTypeBienId: {
        customRequired(value) {
          return this.businessEntries.contratTypeBiens.filter(
            (b) => b.parentId == this.mission.prestationExpertise.contratCategorieDActifId
          ).length > 0
            ? helpers.req(value)
            : true;
        },
      },
      contratConditionOccupationId: {
        required,
      },
      // Dans la version 2 ou moins, c'est obligatoire si le contrat d'occupation est "occupé par le locataire"
      // Dans la version 3, c'est tout le temps obligatoire
      contratHypotheseEvaluationId: {
        required: requiredIf(
          (rapport) =>
            (requireIfVersion(rapport.version, 2.0, true) && isContratConditionOccupationOccupe(rapport.contratConditionOccupationId)) ||
            requireIfVersion(rapport.version, 3.0)
        ),
      },
      contratHypotheseEvaluationAutre: {
        required: requiredIf((rapport) => rapport.contratHypotheseEvaluationId === ContratHypotheseEvaluationEnum.Autre),
      },
      geographiqueSituationGenerale: {
        required,
      },
      geographiqueSituationParticuliere: {
        required,
      },
      geographiqueVueAerienne: {
        required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.3)),
      },
      geographiqueTypeHabitatId: {
        required,
      },
      geographiqueAvisExpert: {
        required,
      },
      situationGeographiqueParticuliereId: {
        required: requiredIf((rapport) => requireIfRapportExpertise(rapport) && requireIfVersion(rapport.version, 3.3)),
      },
      juridiqueAffectationJuridiqueDesLocaux: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport)),
      },
      juridiqueDateAcquisition: {
        required: requiredIf((rapport) => {
          return requireIfCertificatExpertise(rapport) ? false : !rapport.juridiqueDateAcquisitionInconnue;
        }),
      },
      juridiqueModesDeDetentions: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport)),
      },
      juridiqueNomDuProprietaire: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport)),
      },
      juridiqueDesignation: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport) && requireIfVersion(rapport.version, 3.3)),
      },
      juridiquePrixAcquisition: {
        customRequired(value) {
          const rapport = this.mission.prestationExpertise;

          return requireIfVersion(rapport.version, 3.3) &&
            isNotCertificatExpertise(rapport) &&
            !isEtatJuridiquePrixOk(rapport.juridiquePrixAcquisition, rapport.juridiquePrixAcquisitionInconnue)
            ? false
            : true;
        },
      },
      referenceCadastralesRapport: {
        required,
        minLength: minLength(1),
        $each: {
          section: { required },
          parcelle: { required },
          superficie: {},
          /*rapportExpertiseId: {
            required
          }*/
        },
      },
      terrainAccesEtatId: {
        customRequired(value) {
          return !isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      terrainClotureId: {
        required,
      },
      terrainEntretienId: {
        required,
      },
      terrainFormeId: {
        customRequired(value) {
          return !isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      terrainInclinaisonId: {
        customRequired(value) {
          return !isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      terrainSuperficie: {
        customRequired(value) {
          return !isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      terrainServitudes: {
        required,
      },
      terrainPrivatif: {
        customRequired(value) {
          return isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      terrainPrivatifSuperficie: {
        customRequired(value) {
          return isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) &&
            this.mission.prestationExpertise.terrainPrivatif === true
            ? helpers.req(value)
            : true;
        },
      },
      terrainPrivatifClotureId: {
        customRequired(value) {
          return isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) &&
            this.mission.prestationExpertise.terrainPrivatif === true
            ? helpers.req(value)
            : true;
        },
      },
      terrainPrivatifEntretienId: {
        customRequired(value) {
          return isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) &&
            this.mission.prestationExpertise.terrainPrivatif === true
            ? helpers.req(value)
            : true;
        },
      },
      terrainAvisExpert: {
        required,
      },
      fiscaleTaxeFonciere: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            requireIfVersion(this.rapport.version, 2.0, true)
            ? helpers.req(value)
            : true;
        },
      },
      fiscaleSourceTaxeFonciereId: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            requireIfVersion(this.rapport.version, 2.0, true) &&
            this.mission.prestationExpertise.fiscaleTaxeFonciere
            ? helpers.req(value)
            : true;
        },
      },
      fiscaleMontantTaxeFonciere: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            requireIfVersion(this.rapport.version, 2.0, true) &&
            this.mission.prestationExpertise.fiscaleTaxeFonciere
            ? helpers.req(value)
            : true;
        },
      },
      locativeDateBail: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      locativeNatureBailId: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      locativeLoyerMensuelBrut: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      locativeLoyerAnnuelBrut: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      locativeChargesNonRecuperables: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      locativeLoyerAnnuelNet: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      locativeCommentaire: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            isContratConditionOccupationOccupe(this.rapport.contratConditionOccupationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      urbanistiqueCaviteSouterraine: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport) && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueCaviteSouterraines: {
        required: requiredIf((rapport) => rapport.urbanistiqueCaviteSouterraine && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueGonflementDesSolsArgileux: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport) && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueGonflementDesSolsArgileuxSelectionneId: {
        required: requiredIf((rapport) => rapport.urbanistiqueGonflementDesSolsArgileux && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueInondable: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport) && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueInondationId: {
        required: requiredIf((rapport) => rapport.urbanistiqueInondable && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueInstallationIndustrielle: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport) && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueInstallationIndustrielles: {
        required: requiredIf((rapport) => rapport.urbanistiqueInstallationIndustrielle && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueMouvementDeTerrain: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport) && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueMouvementDeTerrains: {
        required: requiredIf((rapport) => rapport.urbanistiqueMouvementDeTerrain && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueSeisme: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport) && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueSeismeSelectionneId: {
        required: requiredIf((rapport) => rapport.urbanistiqueSeisme && !rapport.isSectionRisquesHidden),
      },
      urbanistiquePlanLocalUrbanismeDisponibilite: {
        required: requiredIf((rapport) => isNotCertificatExpertise(rapport)),
      },
      urbanistiquePlanLocalUrbanismeSelectionneId: {
        required: requiredIf((rapport) => rapport.urbanistiquePlanLocalUrbanismeDisponibilite),
      },
      urbanistiqueZone: {
        required: requiredIf((rapport) => rapport.urbanistiquePlanLocalUrbanismeDisponibilite),
      },
      urbanistiqueZoneCommentaire: {
        required: requiredIf((rapport) => rapport.urbanistiquePlanLocalUrbanismeDisponibilite && !rapport.isSectionRisquesHidden),
      },
      urbanistiqueDateApprobation: {
        required: requiredIf((rapport) => rapport.urbanistiquePlanLocalUrbanismeDisponibilite),
      },
      urbanistiqueCommentaire: {
        customRequired(value) {
          return !this.rapport.isSectionRisquesHidden &&
            isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      etudeMarcheAvisExpert: {
        required,
      },
      etudeMarcheElementsComparaisonsLocations: {
        required: requiredIf(
          (rapport) => !rapport.etudeMarcheElementsComparaisonsVentes || rapport.etudeMarcheElementsComparaisonsVentes.length === 0
        ),
        $each: {
          typeDeSourceId: {
            required,
          },
          dateTransaction: {
            required: requiredIf((elementComparaison) => isElementComparaisonTransaction(elementComparaison)),
          },
          description: {
            required,
          },
          surface: {
            required,
            minValue: minValue(1),
          },
          prixOuLoyer: {
            required,
            minValue: minValue(1),
          },
        },
      },
      etudeMarcheElementsComparaisonsVentes: {
        required: requiredIf(
          (rapport) => !rapport.etudeMarcheElementsComparaisonsLocations || rapport.etudeMarcheElementsComparaisonsLocations.length === 0
        ),
        $each: {
          typeDeSourceId: {
            required,
          },
          dateTransaction: {
            required: requiredIf((elementComparaison) => isElementComparaisonTransaction(elementComparaison)),
          },
          description: {
            required,
          },
          surface: {
            required,
            minValue: minValue(1),
          },
          prixOuLoyer: {
            required,
            minValue: minValue(1),
          },
        },
      },
      etudeMarcheForces: {
        required,
        $each: {
          value: { required },
        },
      },
      etudeMarcheFaiblesses: {
        required,
        $each: {
          value: { required },
        },
      },
      etudeMarcheOpportunites: {
        required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.3) && rapport.isRapport),
        $each: {
          value: {
            required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.3) && rapport.isRapport),
          },
        },
      },
      etudeMarcheMenaces: {
        required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.3) && rapport.isRapport),
        $each: {
          value: {
            required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.3) && rapport.isRapport),
          },
        },
      },

      etudeMarcheSectorielle: {
        required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.0)),
      },
      caracteristiquesAnneeConstruction: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      caracteristiquesMitoyennete: {
        customRequired(value) {
          return isTypeDeBienMaison(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      caracteristiquesMitoyenneteCoteId: {
        required: requiredIf('caracteristiquesMitoyennete'),
      },
      caracteristiquesGrosOeuvres: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
        minLength: minLength(1),
        $each: {
          businessEntrySelectedId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      caracteristiquesMenuiseries: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
        minLength: minLength(1),
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      caracteristiquesToitures: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
        minLength: minLength(1),
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      caracteristiquesFacades: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
        minLength: minLength(1),
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      caracteristiqueAssainissementId: {
        customRequired(value) {
          return isTypeDeBienMaison(this.mission.prestationExpertise, this.businessEntries) ? helpers.req(value) : true;
        },
      },
      caracteristiquesDescription: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            requireIfVersion(this.rapport.version, 3.0)
            ? helpers.req(value)
            : true;
        },
      },
      caracteristiquesDPEId: {
        required: requiredIf((rapport) => {
          return isBusinessEntryDPEValid(rapport.annexeEnvironnements) && isRapportVersionGreaterOrEqualsTo(rapport, 3.1);
        }),
      },
      distributionInterieureEtages: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId)) &&
            !isSourceDesSurfacesActeNotarie(this.mission.prestationExpertise.particuliereSourceDesSurfacesId)
            ? helpers.req(value)
            : true;
        },
        $each: {
          typeBusinessItemId: { required },
          customLabel: {
            required: requiredIfTrueForParentVm(isDistributionInterieureEtageTypeAutre),
          },
          distributionInterieureSurfaces: {
            required,
            minLength: minLength(1),
            $each: {
              typeBusinessItemId: { required },
              customLabel: {
                required: requiredIfTrueForParentVm(isDistributionInterieureSurfaceTypeAutre),
              },
              superficie: {
                required: requiredIfTrueForRapport((rapport) => !isSourceDesSurfacesActeNotarie(rapport.particuliereSourceDesSurfacesId)),
              },
            },
          },
        },
      },
      particuliereSourceDesSurfacesId: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      particuliereSurfaceTotaleHabitable: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      prestationsSurfaceAnnexes: {
        $each: {
          typeBusinessItemId: { required },
          customLabel: {
            required: requiredIfTrueForParentVm(isPrestationsSurfaceAnnexeTypeAutre),
          },
          superficie: {
            // pas d'obligation - nécessaire pour simplifier la validation
          },
        },
      },
      prestationsDependances: {
        $each: {
          typeBusinessItemId: { required },
          customLabel: {
            required: requiredIfTrueForParentVm(isPrestationsDependanceTypeAutre),
          },
          superficie: {
            // pas d'obligation - nécessaire pour simplifier la validation
          },
        },
      },
      prestationsAgrements: {
        // Pas d'obligation a priori
      },
      prestationsAvisExpert: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      prestationsCuisines: {
        $each: {
          label: { required },
          equipementId: { required },
          gammeId: { required },
          styleId: { required },
          etatId: { required },
          rapportExpertiseId: { required },
          photoId: { requiredPhotos },
        },
      },
      prestationsEquipements: {
        // devrait plutôt être facultatif
        // required: requiredIf(rapport =>
        //   isContratTypeVisiteInterieurEtExterieur(rapport.contratTypeVisiteId)
        // )
      },
      prestationAvecChauffage: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      prestationsModesChauffages: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId)) &&
            this.mission.prestationExpertiseprestationAvecChauffage
            ? helpers.req(value)
            : true;
        },
      },
      prestationsMurs: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      prestationsPlafonds: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId)) &&
            requireIfVersion(this.mission.prestationExpertise.version, 3.3)
            ? helpers.req(value)
            : true;
        },
      },
      prestationsSallesDeBains: {
        $each: {
          label: { required },
          gammeId: { required },
          etatId: { required },
          rapportExpertiseId: { required },
          photoId: { requiredPhotos },
        },
      },
      prestationsSols: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      prestationsPartiesCommunesId: {
        customRequired(value) {
          return isTypeDeBienAppartement(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      prestationsInstallationElectriqueId: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId)) &&
            requireIfVersion(this.mission.prestationExpertise.version, 3.3)
            ? helpers.req(value)
            : true;
        },
      },
      prestationsIsolationId: {
        customRequired(value) {
          return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId)) &&
            requireIfVersion(this.mission.prestationExpertise.version, 3.3)
            ? helpers.req(value)
            : true;
        },
      },
      prestationsDescription: {
        customRequired(value) {
          return isContratTypePrestationNotCertificatExpertise(this.mission.prestationExpertise.contratTypePrestationId) &&
            requireIfVersion(this.mission.prestationExpertise.version, 3.0) &&
            !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
            (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
              isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId))
            ? helpers.req(value)
            : true;
        },
      },
      evaluationMethodeRetenueId: {
        required,
      },
      evaluationAvisDeLExpert: {
        required,
      },
      evaluationReductionMetriqueTransactionRetenue: {
        required,
      },
      evaluationReductionMetriqueLocationRetenue: {
        required: requiredIf((rapport) => isEvaluationMethodeRetenueComparaisonEtRendement(rapport.evaluationMethodeRetenueId)),
      },
      evaluationTauxRendementRetenu: {
        required: requiredIf((rapport) => isEvaluationMethodeRetenueComparaisonEtRendement(rapport.evaluationMethodeRetenueId)),
      },
      evaluationSurfaceOuSuperficie: {
        required,
      },
      evaluationSurfaceOuSuperficieApresTravaux: {
        required: requiredIf((r) => hasValeurApresTravaux(r)),
      },
      evaluationReductionMetriqueApresTravaux: {
        required: requiredIf((r) => hasValeurApresTravaux(r)),
      },
      evaluationValeurLocativeMensuelle: {
        required: requiredIf((r) => hasValeurLocative(r)),
      },
      evaluationValeurLocativeChargesAnnuelles: {
        required: requiredIf((r) => hasValeurLocative(r)),
      },
      isOkReno: {
        customRequired(value) {
          return hasScenarioRenovation(this.mission);
        },
      },
      adequationMarcheDemandeId: {
        required: requiredIf((rapport) => requireIfRapportExpertise(rapport) && requireIfVersion(rapport.version, 3.3)),
      },
      conclusionValeurVenale: {
        required,
      },
      conclusionValeurVenaleArrondie: {
        customRequired(value) {
          return !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenaleFourchetteBasseArrondie: {
        customRequired(value) {
          return hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenaleFourchetteHauteArrondie: {
        customRequired(value) {
          return hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionAppreciationGeneraleNiveauLiquidite: {
        required: requiredIf((rapport) => !requireIfVersion(rapport.version, 3.3)),
      },
      conclusionValeurVenaleApresTravaux: {
        customRequired(value) {
          return hasValeurApresTravaux(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenaleApresTravauxArrondie: {
        customRequired(value) {
          return hasValeurApresTravaux(this.mission) && !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenaleApresTravauxFourchetteBasseArrondie: {
        customRequired(value) {
          return hasValeurApresTravaux(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenaleApresTravauxFourchetteHauteArrondie: {
        customRequired(value) {
          return hasValeurApresTravaux(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurLocativeAnnuelle: {
        customRequired(value) {
          return hasValeurLocative(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurLocativeAnnuelleArrondie: {
        customRequired(value) {
          return hasValeurLocative(this.mission) && !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurLocativeAnnuelleFourchetteBasseArrondie: {
        customRequired(value) {
          return hasValeurLocative(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurLocativeAnnuelleFourchetteHauteArrondie: {
        customRequired(value) {
          return hasValeurLocative(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionAbattementVenteForcee: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteForcee: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteForceeArrondie: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) && !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteForceeFourchetteBasseArrondie: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteForceeFourchetteHauteArrondie: {
        customRequired(value) {
          return hasValeurVenteForcee(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionAbattementVenteRapide: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteRapide: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteRapideArrondie: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) && !hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteRapideFourchetteBasseArrondie: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionValeurVenteRapideFourchetteHauteArrondie: {
        customRequired(value) {
          return hasValeurVenteRapide(this.mission) && hasFourchetteValeur(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionVariationValeur: {
        customRequired(value) {
          return hasOptionFaciliteDeNegociationESFI(this.mission) ? helpers.req(value) : true;
        },
      },
      conclusionNiveauLiquiditeESFIId: {
        // customRequired(value) {
        //   const rapport = this.mission.prestationExpertise;
        //   return !requireIfVersion(rapport.version, 3.3) ? true : hasOptionFaciliteDeNegociationESFI(this.mission) && helpers.req(value);
        // },
      },
      conclusionDelaiDeVenteMoyenId: {
        required,
      },
      conclusionDateValeur: {
        required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.0)),
      },
      conclusionObservationValeur: {
        required: requiredIf((rapport) => requireIfVersion(rapport.version, 3.2)),
      },
      piecesJointesRapport: {
        PlanCadastre: {
          required,
        },
        SectionTerrain: {
          requiredPhotos,
        },
        SectionGrosOeuvre: {
          customRequired(value) {
            return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
              this.mission.prestationExpertise.proprietes.photosObligatoires
              ? helpers.req(value)
              : true;
          },
        },
        SectionSurface: {
          customRequired(value) {
            return !isTypeDeBienTerrain(this.mission.prestationExpertise, this.businessEntries) &&
              (isContratTypeVisiteInterieurEtExterieur(this.mission.prestationExpertise.contratTypeVisiteId) ||
                isContratTypeVisiteSurPieces(this.mission.prestationExpertise.contratTypeVisiteId)) &&
              this.mission.prestationExpertise.proprietes.photosObligatoires
              ? helpers.req(value)
              : true;
          },
        },
      },
      juridiqueDocumentConsultes: {
        customRequired(value) {
          return isNotCertificatExpertise(this.mission.prestationExpertise) && areBusinessEntryRelationsValid(value)
            ? helpers.req(value)
            : true;
        },
        $each: {
          businessEntrySelectedId: {
            required: requiredIf((rapport) => isNotCertificatExpertise(rapport)),
          },
          businessEntryQualificationId: {
            required: requiredIf((rapport) => isNotCertificatExpertise(rapport)),
          },
          rapportExpertiseId: {
            required: requiredIf((rapport) => isNotCertificatExpertise(rapport)),
          },
        },
      },
      annexeConstructions: {
        customRequired(value) {
          return areBusinessEntryRelationsValid(value);
        },
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      annexeSurfaces: {
        customRequired(value) {
          return areBusinessEntryRelationsValid(value);
        },
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      annexeSituationsLocatives: {
        customRequired(value) {
          return requiredIf(areBusinessEntryRelationsValid(value)) ? true : false;
        },
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      annexeTravaux: {
        customRequired(value) {
          return requiredIf(areBusinessEntryRelationsValid(value)) ? true : false;
        },
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
      annexeEnvironnements: {
        customRequired(value) {
          return requiredIf(areBusinessEntryRelationsValid(value)) ? true : false;
        },
        $each: {
          businessEntrySelectedId: {
            required,
          },
          businessEntryQualificationId: {
            required,
          },
          rapportExpertiseId: {
            required,
          },
        },
      },
    },
    contratGroup: [
      'mission.prestationExpertise.contratVisitePresenceContact',
      'mission.prestationExpertise.contratVisitePresenceQualificatifId',
      'mission.prestationExpertise.contratVisitePresenceCiviliteId',
      'mission.prestationExpertise.contratTypeVisiteId',
      'mission.prestationExpertise.contratCategorieDActifId',
      'mission.prestationExpertise.contratTypeBienId',
      'mission.prestationExpertise.contratConditionOccupationId',
      'mission.prestationExpertise.contratHypotheseEvaluationId',
      'mission.prestationExpertise.contratHypotheseEvaluationAutre',
      'mission.dateVisite',
      'mission.prestationExpertise.nbKmEffectues',
    ],
    situationGeographiqueGroup: [
      'mission.prestationExpertise.geographiqueSituationGenerale',
      'mission.prestationExpertise.geographiqueSituationParticuliere',
      'mission.prestationExpertise.geographiqueVueAerienne',
      'mission.prestationExpertise.geographiqueTypeHabitatId',
      'mission.prestationExpertise.geographiqueAvisExpert',
      'mission.prestationExpertise.situationGeographiqueParticuliereId',
    ],
    descriptionGeneraleGroup: [
      'mission.prestationExpertise.referenceCadastralesRapport',
      'mission.prestationExpertise.piecesJointesRapport.PlanCadastre',
      'mission.prestationExpertise.piecesJointesRapport.SectionTerrain',
      'mission.prestationExpertise.terrainAccesEtatId',
      'mission.prestationExpertise.terrainClotureId',
      'mission.prestationExpertise.terrainEntretienId',
      'mission.prestationExpertise.terrainFormeId',
      'mission.prestationExpertise.terrainInclinaisonId',
      'mission.prestationExpertise.terrainSuperficie',
      'mission.prestationExpertise.terrainServitudes',
      'mission.prestationExpertise.terrainPrivatif',
      'mission.prestationExpertise.terrainPrivatifSuperficie',
      'mission.prestationExpertise.terrainPrivatifClotureId',
      'mission.prestationExpertise.terrainPrivatifEntretienId',
      'mission.prestationExpertise.terrainAvisExpert',
    ],
    descriptionParticuliereGroup: [
      'mission.prestationExpertise.caracteristiquesAnneeConstruction',
      'mission.prestationExpertise.caracteristiquesMitoyennete',
      'mission.prestationExpertise.caracteristiquesMitoyenneteCoteId',
      'mission.prestationExpertise.caracteristiquesGrosOeuvres',
      'mission.prestationExpertise.caracteristiquesMenuiseries',
      'mission.prestationExpertise.caracteristiquesToitures',
      'mission.prestationExpertise.caracteristiquesFacades',
      'mission.prestationExpertise.caracteristiqueAssainissementId',
      'mission.prestationExpertise.caracteristiquesDescription',
      'mission.prestationExpertise.caracteristiquesDPEId',
      'mission.prestationExpertise.piecesJointesRapport.SectionGrosOeuvre',
      'mission.prestationExpertise.piecesJointesRapport.SectionSurface',
      'mission.prestationExpertise.distributionInterieureEtages',
      'mission.prestationExpertise.prestationsMurs',
      'mission.prestationExpertise.prestationsSols',
      'mission.prestationExpertise.prestationAvecChauffage',
      'mission.prestationExpertise.prestationsModesChauffages',
      'mission.prestationExpertise.prestationsEquipements',
      'mission.prestationExpertise.prestationsAgrements',
      'mission.prestationExpertise.prestationsAvisExpert',
      'mission.prestationExpertise.prestationsCuisines',
      'mission.prestationExpertise.prestationsSallesDeBains',
      'mission.prestationExpertise.prestationsSurfaceAnnexes',
      'mission.prestationExpertise.prestationsDependances',
      'mission.prestationExpertise.prestationsPartiesCommunesId',
      'mission.prestationExpertise.prestationsInstallationElectriqueId',
      'mission.prestationExpertise.prestationsDescription',
      'mission.prestationExpertise.particuliereSourceDesSurfacesId',
      'mission.prestationExpertise.particuliereSurfaceTotaleHabitable',
      'mission.prestationExpertise.prestationsPlafonds',
      'mission.prestationExpertise.prestationsIsolationId',
    ],
    situationFiscaleGroup: [
      'mission.prestationExpertise.fiscaleTaxeFonciere',
      'mission.prestationExpertise.fiscaleSourceTaxeFonciereId',
      'mission.prestationExpertise.fiscaleMontantTaxeFonciere',
    ],
    situationLocativeGroup: [
      'mission.prestationExpertise.locativeDateBail',
      'mission.prestationExpertise.locativeNatureBailId',
      'mission.prestationExpertise.locativeLoyerMensuelBrut',
      'mission.prestationExpertise.locativeLoyerAnnuelBrut',
      'mission.prestationExpertise.locativeChargesNonRecuperables',
      'mission.prestationExpertise.locativeLoyerAnnuelNet',
      'mission.prestationExpertise.locativeCommentaire',
    ],
    situationJuridiqueGroup: [
      'mission.prestationExpertise.juridiqueAffectationJuridiqueDesLocaux',
      'mission.prestationExpertise.juridiqueDateAcquisition',
      'mission.prestationExpertise.juridiqueModesDeDetentions',
      'mission.prestationExpertise.juridiqueNomDuProprietaire',
      'mission.prestationExpertise.juridiqueDocumentConsultes',
      'mission.prestationExpertise.juridiqueDesignation',
      'mission.prestationExpertise.juridiquePrixAcquisition',
    ],
    situationUrbanistiqueGroup: [
      'mission.prestationExpertise.urbanistiqueCaviteSouterraine',
      'mission.prestationExpertise.urbanistiqueCaviteSouterraines',
      'mission.prestationExpertise.urbanistiqueGonflementDesSolsArgileux',
      'mission.prestationExpertise.urbanistiqueGonflementDesSolsArgileuxSelectionneId',
      'mission.prestationExpertise.urbanistiqueInondable',
      'mission.prestationExpertise.urbanistiqueInondationId',
      'mission.prestationExpertise.urbanistiqueInstallationIndustrielle',
      'mission.prestationExpertise.urbanistiqueInstallationIndustrielles',
      'mission.prestationExpertise.urbanistiqueMouvementDeTerrain',
      'mission.prestationExpertise.urbanistiqueMouvementDeTerrains',
      'mission.prestationExpertise.urbanistiqueSeisme',
      'mission.prestationExpertise.urbanistiqueSeismeSelectionneId',
      'mission.prestationExpertise.urbanistiquePlanLocalUrbanismeDisponibilite',
      'mission.prestationExpertise.urbanistiquePlanLocalUrbanismeSelectionneId',
      'mission.prestationExpertise.urbanistiqueZone',
      'mission.prestationExpertise.urbanistiqueZoneCommentaire',
      'mission.prestationExpertise.urbanistiqueCommentaire',
    ],
    etudeMarcheGroup: [
      'mission.prestationExpertise.etudeMarcheAvisExpert',
      'mission.prestationExpertise.etudeMarcheElementsComparaisonsLocations',
      'mission.prestationExpertise.etudeMarcheElementsComparaisonsVentes',
      'mission.prestationExpertise.etudeMarcheForces',
      'mission.prestationExpertise.etudeMarcheFaiblesses',
      'mission.prestationExpertise.etudeMarcheOpportunites',
      'mission.prestationExpertise.etudeMarcheMenaces',
      'mission.prestationExpertise.etudeMarcheSectorielle',
    ],
    evaluationGroup: [
      'mission.prestationExpertise.evaluationMethodeRetenueId',
      'mission.prestationExpertise.evaluationAvisDeLExpert',
      'mission.prestationExpertise.evaluationReductionMetriqueTransactionRetenue',
      'mission.prestationExpertise.evaluationReductionMetriqueLocationRetenue',
      'mission.prestationExpertise.evaluationTauxRendementRetenu',
      'mission.prestationExpertise.evaluationSurfaceOuSuperficie',
      'mission.prestationExpertise.evaluationSurfaceOuSuperficieApresTravaux',
      'mission.prestationExpertise.evaluationReductionMetriqueApresTravaux',
      'mission.prestationExpertise.evaluationValeurLocativeMensuelle',
      'mission.prestationExpertise.evaluationValeurLocativeChargesAnnuelles',
      'mission.prestationExpertise.evaluationReductionMetriqueApresTravaux',
      'mission.prestationExpertise.isOkReno',
    ],
    conclusionGroup: [
      'mission.prestationExpertise.conclusionValeurVenaleArrondie',
      'mission.prestationExpertise.conclusionValeurVenale',
      'mission.prestationExpertise.conclusionValeurVenaleFourchetteBasseArrondie',
      'mission.prestationExpertise.conclusionValeurVenaleFourchetteHauteArrondie',
      'mission.prestationExpertise.conclusionAppreciationGeneraleNiveauLiquidite',
      'mission.prestationExpertise.conclusionValeurVenaleApresTravauxArrondie',
      'mission.prestationExpertise.conclusionValeurVenaleApresTravaux',
      'mission.prestationExpertise.conclusionValeurVenaleApresTravauxFourchetteBasseArrondie',
      'mission.prestationExpertise.conclusionValeurVenaleApresTravauxFourchetteHauteArrondie',
      'mission.prestationExpertise.conclusionValeurLocativeAnnuelleArrondie',
      'mission.prestationExpertise.conclusionValeurLocativeAnnuelle',
      'mission.prestationExpertise.conclusionValeurLocativeAnnuelleFourchetteBasseArrondie',
      'mission.prestationExpertise.conclusionValeurLocativeAnnuelleFourchetteHauteArrondie',
      'mission.prestationExpertise.conclusionAbattementVenteForcee',
      'mission.prestationExpertise.conclusionValeurVenteForcee',
      'mission.prestationExpertise.conclusionValeurVenteForceeArrondie',
      'mission.prestationExpertise.conclusionValeurVenteForceeFourchetteBasseArrondie',
      'mission.prestationExpertise.conclusionValeurVenteForceeFourchetteHauteArrondie',
      'mission.prestationExpertise.conclusionAbattementVenteRapide',
      'mission.prestationExpertise.conclusionValeurVenteRapide',
      'mission.prestationExpertise.conclusionValeurVenteRapideArrondie',
      'mission.prestationExpertise.conclusionValeurVenteRapideFourchetteBasseArrondie',
      'mission.prestationExpertise.conclusionVariationValeur',
      'mission.prestationExpertise.conclusionNiveauLiquiditeESFIId',
      'mission.prestationExpertise.conclusionDelaiDeVenteMoyenId',
      'mission.prestationExpertise.conclusionDateValeur',
      'mission.prestationExpertise.annexeConstructions',
      'mission.prestationExpertise.annexeSurfaces',
      'mission.prestationExpertise.annexeSituationsLocatives',
      'mission.prestationExpertise.annexeTravaux',
      'mission.prestationExpertise.annexeEnvironnements',
      'mission.prestationExpertise.adequationMarcheDemandeId',
    ],
  },
};

export { rapportExpertiseValidation };
