





























































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import Radios from '@/shared/components/radios.vue';
import { required } from 'vuelidate/lib/validators';
import { PrestationSalleDeBainDto } from '@/shared/dtos/prestation-salle-de-bain.dto';

@Component({
  name: 'ExpSalleDeBain',
  validations: {
    newLabel: { required },
  },
  components: { RapportExpertiseFileUploader, Radios },
})
export default class ExpSalleDeBain extends Vue {
  @Inject('onDeleteSalleDeBainClick') public onDeleteSalleDeBainClick: any;

  @Prop() public value: PrestationSalleDeBainDto;
  @Prop() public index: number;
  @Prop() public piecesJointes: any;
  @Prop() public businessEntries: any;
  @Prop() public validation: any;

  public isEditing: boolean = false;
  public newLabel: string = null;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  get rapport() {
    return this.value;
  }

  get salleDeBain() {
    return this.value;
  }

  //#endregion

  //#region WATCH

  @Watch('piecesJointes.salleDeBain')
  public salleDeBainChanged(newValue: any) {
    if (!newValue.some((pj: any) => pj.id == this.value.photoId)) this.value.photoId = null; // gère le cas où on supprime la photo uniquement
  }

  //#endregion

  //#region EVENTS
  public onEditClick(): void {
    this.isEditing = true;
    this.newLabel = this.value.label;
    this.$nextTick(() => {
      let el = (this.$refs.inputNewLabel as any).$el;
      el.focus();
      el.setSelectionRange(el.value.length, el.value.length);
    });
  }
  public onUpdateClick(): void {
    if (this.$v.newLabel.$invalid) return;
    this.isEditing = false;
    this.value.label = this.newLabel;
  }

  public onCancelClick(): void {
    this.isEditing = false;
  }

  public onUploadedFileId(id: number) {
    if (id != null && id !== 0) this.value.photoId = id;
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
