import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import { BaseDtoApiService } from './base-dto-api.service';
import axios from 'axios';
import { SocieteSettings } from '@/shared/models/societe-settings.model';

export class PermissionsService {
  constructor() {}

  public getSocieteSettings(societeId: number): Promise<SocieteSettings> {
    return axios.get(`societes/${societeId}/settings`).then((response: any) => {
      return response.data.result as SocieteSettings;
    });
  }
}
