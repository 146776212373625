



































































import { Component, Inject, Mixins, Prop, Vue } from 'vue-property-decorator';
import { Dpe } from '@/shared/models/dpe.model';
import { DpeEnum } from '@/shared/constants/tableDPE.const';
import { Renovation, RenovationScenarioDetailed } from '@/shared/dtos/scenario-renovation.dto';
import EtiquetteDpe from '../etiquette-dpe.vue';
import { PropertyDossier } from '@/shared/models/dossier/property.model';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { toOnlyTextAndNumberLower } from '@/shared/utilities/string.utility';

@Component({
  name: 'apercu-renovation',
  components: { EtiquetteDpe },
})
export default class ApercuRenovation extends Vue {
  @Prop() public propertyDossier: PropertyDossier;
  @Prop() public scenario: RenovationScenarioDetailed;
  @Prop() public mission: MissionDto;
  @Prop() public caracteristiqueEnergetiqueReferentiel: Record<string, string>;

  public targetDpe: Dpe = DpeEnum.G;
  public actualDpe: Dpe = DpeEnum.G;

  public refDpe: Dpe[] = Object.values(DpeEnum);
  public caracteristiqueActuelle: Record<string, number | string>;
  public plusValue: number;

  //#region LIFE CYCLES
  beforeMount() {
    this.targetDpe = EtiquetteDpe.getDPE(this.scenario.energyLabel.value);
    this.actualDpe = EtiquetteDpe.getDPE(this.mission.scenarioRenovation.currentEnergyLabel.value);
    this.caracteristiqueActuelle = this.toFlatKeyValueDictionary(this.propertyDossier);
  }

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH
  //#endregion
  //#region EVENTS

  //#endregion
  //#region FUNCTIONS

  toFlatKeyValueDictionary(obj: any): Record<string, number | string> {
    let result: Record<string, number | string> = {};
    for (const key in obj) {
      const value = obj[key];
      if (typeof value == 'number' || typeof value == 'string' || value === null) {
        result[this.getToOnlyTextAndNumberLower(key)] = value;
      }
    }

    return result;
  }

  private getValueFromreferentiel(key: string) {
    if (this.getToOnlyTextAndNumberLower(key) in this.caracteristiqueEnergetiqueReferentiel)
      return this.caracteristiqueEnergetiqueReferentiel[toOnlyTextAndNumberLower(key)];

    Vue.prototype.$logStoreService.error(
      `Scenario Renovation - ERROR - ${toOnlyTextAndNumberLower(key)} n'est pas défini dans le referentiel`
    );
    return ' ';
  }

  private getToOnlyTextAndNumberLower(input: string) {
    return toOnlyTextAndNumberLower(input);
  }

  private isSameValue(renovation: Renovation) {
    const currentValue = this.getValueFromreferentiel(
      this.caracteristiqueActuelle[this.getToOnlyTextAndNumberLower(renovation.type)].toString()
    );
    const renovationValue = this.getValueFromreferentiel(renovation.value);

    return currentValue === renovationValue ? currentValue : `${currentValue} → ${renovationValue}`;
  }
  //#endregion
}
