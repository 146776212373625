import { render, staticRenderFns } from "./exp-cuisine.vue?vue&type=template&id=97844146&scoped=true&"
import script from "./exp-cuisine.vue?vue&type=script&lang=ts&"
export * from "./exp-cuisine.vue?vue&type=script&lang=ts&"
import style0 from "./exp-cuisine.vue?vue&type=style&index=0&id=97844146&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97844146",
  null
  
)

export default component.exports