

























































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import TextareaWithDefaultValue from '@/shared/components/textarea-with-default-value.vue';
import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import PrestationExpertiseRapportDescriptionGeneraleReferencesCadastrales from './prestation-expertise-rapport-description-generale-references-cadastrales.vue';
import PrestationExpertiseRapportDescriptionGeneraleAvisExpert from './prestation-expertise-rapport-description-generale-avis-expert.vue';
import RadioBoolean from '@/shared/components/radio-boolean.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import VisaDisplay from '@/shared/components/visa-display.vue';
import { isNullOrEmpty } from '@/shared/utilities/string.utility';
import { DonneesEstimationImmobiliereUpdateInputDto } from '@/shared/dtos/donnees-estimation-immobiliere-update-input.dto';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';
import { AnnexeStatutEnum } from '@/shared/enums/annexe-statut.enum';

@Component({
  name: 'PrestationExpertiseRapportDescriptionGeneraleTerrain',
  components: {
    TextareaWithDefaultValue,
    RapportExpertiseFileUploader,
    PrestationExpertiseRapportDescriptionGeneraleReferencesCadastrales,
    RadioBoolean,
    NumericNullable,
    ChoicePopup,
    VisaDisplay,
    PrestationExpertiseRapportDescriptionGeneraleAvisExpert,
  },
})
export default class PrestationExpertiseRapportDescriptionGeneraleTerrain extends Mixins(RapportExpertiseSectionMixin) {
  //#region LIFE CYCLES
  public mounted(): void {
    if (this.isTypeDeBienTerrain) {
      const annexeDPE = this.rapport.annexeEnvironnements.find((x: any) => x.businessEntrySelectedId === BusinessEntryEnum.DPE);
      if (annexeDPE) annexeDPE.businessEntryQualificationId = AnnexeStatutEnum.NonObtenu;
    }
  }

  //#endregion

  //#region COMPUTED
  get superficieReferencesCadastrales(): number {
    return this.rapport.referenceCadastralesRapport.reduce((sum: any, curr: any) => sum + (curr.superficie || 0), 0);
  }

  //#endregion

  //#region WATCH
  @Watch('typeDeBien')
  public typeDeBienChanged(): void {
    if (this.isTypeDeBienAppartement) {
      this.rapport.terrainSuperficie = null;
      this.rapport.terrainFormeId = null;
      this.rapport.terrainAccesEtatId = null;
      this.rapport.terrainInclinaisonId = null;
    } else {
      this.rapport.terrainPrivatif = null;
      this.rapport.terrainPrivatifSuperficie = null;
      this.rapport.terrainPrivatifClotureId = null;
      this.rapport.terrainPrivatifEntretienId = null;
      this.rapport.prestationsPartiesCommunesId = null;
    }

    if (this.isTypeDeBienTerrain) {
      const annexeDPE = this.rapport.annexeEnvironnements.find((x: any) => x.businessEntrySelectedId === BusinessEntryEnum.DPE);
      if (annexeDPE) annexeDPE.businessEntryQualificationId = AnnexeStatutEnum.NonObtenu;
    }
  }

  @Watch('superficieReferencesCadastrales')
  public superficieReferencesCadastralesChanged(newValue: any, oldValue: any) {
    if (!this.isTypeDeBienAppartement && this.rapport.terrainSuperficie == oldValue) {
      this.rapport.terrainSuperficie = newValue;
    }
  }

  @Watch('rapport.terrainSuperficie')
  public terrainSuperficieChanged(value: any) {
    if (this.isTypeDeBienTerrain) {
      this.rapport.evaluationSurfaceOuSuperficie = value;
    }
  }

  @Watch('rapport.terrainPrivatif')
  public terrainPrivatifChanged(): void {
    if (this.rapport.terrainPrivatif === false) {
      this.rapport.terrainPrivatifSuperficie = null;
      this.rapport.terrainPrivatifClotureId = null;
      this.rapport.terrainPrivatifEntretienId = null;
    }
  }

  //#endregion

  //#region EVENTS
  public async onCalculateSuperficieTerrainClick(): Promise<void> {
    this.rapport.terrainSuperficie = this.superficieReferencesCadastrales;
    await this.onTerrainSuperficieChange(this.rapport.terrainSuperficie);
  }

  public async onTerrainSuperficieChange(value: number): Promise<void> {
    if (this.isMobile || isNullOrEmpty(value) || value === 0) return;
    var input = new DonneesEstimationImmobiliereUpdateInputDto();
    input.landArea = value;
    await Vue.prototype.$rapportExpertiseApiService.updateDonneesEstimationImmobiliere(this.rapport.id, input);
  }

  public onEmitZoomPlanCadastreChanged(value: number): void {
    this.rapport.geographiqueZoomPlanCadastre = value;
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
