









































































































































































































































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaDisplay from '@/shared/components/visa-display.vue';
import VisaNote from '@/shared/components/visa-note.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import Radios from '@/shared/components/radios.vue';
import {
  contratTypePrestationColor,
  isEvaluationMethodeRetenueComparaison,
  isEvaluationMethodeRetenueComparaisonEtRendement,
  isEvaluationMethodeRetenueRendement,
  hasValeurApresTravaux,
  hasValeurLocative,
  hasOptionFaciliteDeNegociationESFI,
} from '@/helpers/rapport-expertise.helper';
import { hasFourchetteValeur, hasValeurVenteForcee, hasValeurVenteRapide } from '@/helpers/prestation-expertise.helper';
import Decimal from 'decimal.js';
import vueSlider from 'vue-slider-component';
import StarRating from 'vue-star-rating';
import { hasValidNumberValue } from '@/shared/utilities/type.utility';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { SubjectMessage } from '@/shared/models/subject-message';
import DatePicker from '@/shared/components/date-picker.vue';
import { RapportExpertiseBusinessEntryDto } from '@/shared/dtos/rapport-expertise-business-entry.dto';
import { hasFlag } from '@/shared/utilities/enums.utility';
import { FeatureEnum } from '@/shared/enums/feature.enum';

@Component({
  name: 'PrestationExpertiseRapportConclusionConclusion',
  components: {
    VisaNote,
    VisaDisplay,
    NumericNullable,
    vueSlider,
    StarRating,
    Radios,
    DatePicker,
  },
})
export default class PrestationExpertiseRapportConclusionConclusion extends Mixins(RapportExpertiseSectionMixin) {
  public currentRating: string = null;
  public ratings: string[] = ['', 'Médiocre niveau', 'Faible niveau', 'Niveau moyen', 'Bon niveau', 'Très bon niveau'];

  public percentFourchetteBasse: number = 0.975;
  public percentFourchetteHaute: number = 1.025;
  public valeurLocativeRoundAt: number = 100;

  public get conclusionNiveauxLiquiditeESFIBusinessEntries(): RapportExpertiseBusinessEntryDto[] {
    const hideMediocre =
      this.societeSettings &&
      hasFlag(this.societeSettings.settings, FeatureEnum.HideNiveauDeLiquiditeMediocre) &&
      this.mission.societeId == this.societeSettings.societeId;

    if (hideMediocre) {
      const entries = this.businessEntries.conclusionNiveauxLiquiditeESFI.filter(
        (be: RapportExpertiseBusinessEntryDto) => be.displayText.toLowerCase() != 'médiocre'
      );

      // Cette méthode permet d'éviter les "trous" dans le nombre d'étoiles (par exemple ça évite d'avoir : 1 étoile, 3 étoiles, 4 étoiles, 5 étoiles si
      // l'item correspondant à 2 étoiles - médiocre a été supprimé par le filtre précédent)
      // entries.forEach((be: RapportExpertiseBusinessEntryDto, index: number) => (be.iconRepetition = index + 1));

      return entries;
    } else return this.businessEntries.conclusionNiveauxLiquiditeESFI;
  }

  //#region LIFE CYCLES
  public mounted(): void {
    this.showCurrentRating(this.rapport.conclusionAppreciationGeneraleNiveauLiquidite);

    Vue.prototype.$subjectMessageService.subject.subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.REFRESH_VALEURS_DATA) {
        this.updateValeurs();
      }
    });
  }

  //#endregion

  //#region COMPUTED
  get sliderStyles() {
    var color = contratTypePrestationColor(this.rapport);
    return {
      sliderProcessStyle: {
        backgroundColor: color,
      },
      sliderTooltipStyle: {
        backgroundColor: color,
        borderColor: color,
      },
    };
  }

  get isEvaluationMethodeRetenueComparaison(): boolean {
    return isEvaluationMethodeRetenueComparaison(this.rapport.evaluationMethodeRetenueId);
  }

  get isEvaluationMethodeRetenueComparaisonEtRendement(): boolean {
    return isEvaluationMethodeRetenueComparaisonEtRendement(this.rapport.evaluationMethodeRetenueId);
  }

  get isEvaluationMethodeRetenueRendement(): boolean {
    return isEvaluationMethodeRetenueRendement(this.rapport.evaluationMethodeRetenueId);
  }

  get comparaisonIsCalculable(): boolean {
    return (
      hasValidNumberValue(this.rapport.evaluationReductionMetriqueTransactionRetenue) &&
      hasValidNumberValue(this.rapport.evaluationSurfaceOuSuperficie)
    );
  }

  get comparaisonIsCalculableReason(): string {
    if (!this.rapport.evaluationReductionMetriqueTransactionRetenue) {
      return 'Réduction métrique transaction retenue manquante';
    }

    if (!this.rapport.evaluationSurfaceOuSuperficie) {
      if (this.isTypeDeBienTerrain) {
        return 'Superficie du terrain manquante';
      } else {
        return 'Surface totale habitable manquante';
      }
    }

    return 'Information(s) manquante(s) pour calculer la valeur.';
  }

  get comparaison(): number {
    return new Decimal(this.rapport.evaluationReductionMetriqueTransactionRetenue || 0)
      .times(this.rapport.evaluationSurfaceOuSuperficie || 0)
      .floor()
      .toNumber();
  }

  get roundComparaison(): number {
    return this.round(this.comparaison);
  }

  get hasValeurApresTravaux(): boolean {
    return hasValeurApresTravaux(this.mission);
  }

  get comparaisonApresTravauxIsCalculable(): boolean {
    return (
      hasValidNumberValue(this.rapport.evaluationReductionMetriqueApresTravaux) &&
      hasValidNumberValue(this.rapport.evaluationSurfaceOuSuperficieApresTravaux)
    );
  }

  get comparaisonApresTravauxIsCalculableReason(): string {
    if (!this.rapport.evaluationReductionMetriqueApresTravaux) {
      return 'Réduction métrique transaction retenue après travaux manquante';
    }

    if (!this.rapport.evaluationSurfaceOuSuperficieApresTravaux) {
      if (this.isTypeDeBienTerrain) {
        return 'Superficie du terrain après travaux manquante';
      } else {
        return 'Surface totale habitable après travaux manquante';
      }
    }

    return 'Information(s) manquante(s) pour calculer la valeur.';
  }

  get comparaisonApresTravaux(): number {
    return new Decimal(this.rapport.evaluationReductionMetriqueApresTravaux || 0)
      .times(this.rapport.evaluationSurfaceOuSuperficieApresTravaux || 0)
      .floor()
      .toNumber();
  }

  get roundComparaisonApresTravaux(): number {
    return this.round(this.comparaisonApresTravaux);
  }

  get rendementIsCalculable(): boolean {
    return (
      hasValidNumberValue(this.rapport.evaluationReductionMetriqueLocationRetenue) &&
      hasValidNumberValue(this.rapport.evaluationSurfaceOuSuperficie) &&
      hasValidNumberValue(this.rapport.evaluationTauxRendementRetenu) &&
      hasValidNumberValue(this.rapport.evaluationDroitEnregistrement)
    );
  }

  get rendementIsCalculableReason(): string {
    if (!this.rapport.evaluationReductionMetriqueLocationRetenue) {
      return 'Réduction métrique location retenue manquante';
    }

    if (!this.rapport.evaluationSurfaceOuSuperficie) {
      if (this.isTypeDeBienTerrain) {
        return 'Superficie du terrain manquante';
      } else {
        return 'Surface de construction manquante';
      }
    }

    if (!this.rapport.evaluationTauxRendementRetenu) {
      return 'Taux de rendement retenu manquant';
    }

    if (!this.rapport.evaluationDroitEnregistrement) {
      return "Droits d'enregistrement manquant";
    }

    return 'Information(s) manquante(s) pour calculer la valeur.';
  }

  get rendement(): number {
    if (this.rapport.evaluationTauxRendementRetenu) {
      var part1 = new Decimal(this.rapport.evaluationReductionMetriqueLocationRetenue || 0)
        .times(this.rapport.evaluationSurfaceOuSuperficie || 0)
        .times(12);

      const part3: Decimal = new Decimal(this.rapport.evaluationDroitEnregistrement).plus(1);

      return new Decimal(part1).dividedBy(this.rapport.evaluationTauxRendementRetenu).dividedBy(part3).floor().toNumber();
    }

    return 0;
  }

  get roundRendement(): number {
    return this.round(this.rendement);
  }

  get currentSelectedRating(): string {
    return this.rapport.conclusionAppreciationGeneraleNiveauLiquidite > 0
      ? this.ratings[this.rapport.conclusionAppreciationGeneraleNiveauLiquidite] + ' de liquidité à la valeur vénale établie'
      : '&nbsp;';
  }

  get hasValeurLocative(): boolean {
    return hasValeurLocative(this.mission);
  }

  get hasFourchetteValeur(): boolean {
    return hasFourchetteValeur(this.mission);
  }

  get hasValeurVenteForcee(): boolean {
    return hasValeurVenteForcee(this.mission);
  }

  get hasValeurVenteRapide(): boolean {
    return hasValeurVenteRapide(this.mission);
  }

  get hasOptionFaciliteDeNegociationESFI(): boolean {
    return hasOptionFaciliteDeNegociationESFI(this.mission);
  }

  get abattementVenteRapideDisplaytext(): string {
    const percent = new Decimal(this.rapport.conclusionAbattementVenteRapide || 0).times(100).toNumber();
    return `${percent} %`;
  }

  get abattementVenteForceeDisplaytext(): string {
    const percent = new Decimal(this.rapport.conclusionAbattementVenteForcee || 0).times(100).toNumber();
    return `${percent} %`;
  }

  //#endregion

  //#region WATCH
  @Watch('rapport.evaluationMethodeRetenueId')
  public evaluationMethodeRetenueIdChanged(): void {
    if (this.isEvaluationMethodeRetenueComparaison) {
      this.rapport.conclusionValeurVenale = this.comparaison;
    } else if (this.isEvaluationMethodeRetenueRendement) {
      this.rapport.conclusionValeurVenale = this.rendement;
    }
  }

  @Watch('comparaison')
  public comparaisonChanged(): void {
    if (this.isEvaluationMethodeRetenueComparaison) {
      this.rapport.conclusionValeurVenale = this.comparaison || 0;
    }
  }

  @Watch('rendement')
  public rendementChanged(): void {
    if (this.isEvaluationMethodeRetenueRendement) {
      this.rapport.conclusionValeurVenale = this.rendement || 0;
    }
  }

  @Watch('rapport.conclusionValeurVenale')
  public conclusionValeurVenaleChanged(): void {
    this.updateConclusionValeurVenaleArrondie();
    this.updateConclusionValeurVenaleFourchettesArrondies();
    this.updateConclusionValeurVenteRapide();
    this.updateConclusionValeurVenteForcee();
  }

  @Watch('rapport.conclusionAbattementVenteRapide')
  public conclusionAbattementonEmitVenteRapide(): void {
    this.updateConclusionValeurVenteRapide();
  }

  @Watch('rapport.conclusionValeurVenteRapide')
  public conclusionValeuronEmitVenteRapide(newValue: number) {
    this.rapport.conclusionValeurVenteRapideArrondie = !this.hasFourchetteValeur && this.hasValeurVenteRapide ? this.round(newValue) : null;
    this.updateConclusionValeurVenteRapideFourchettesArrondies();
  }

  @Watch('rapport.conclusionAbattementVenteForcee')
  public conclusionAbattementonEmitVenteForcee(): void {
    this.updateConclusionValeurVenteForcee();
  }

  @Watch('rapport.conclusionValeurVenteForcee')
  public conclusionValeuronEmitVenteForcee(newValue: number): void {
    this.rapport.conclusionValeurVenteForceeArrondie = !this.hasFourchetteValeur && this.hasValeurVenteForcee ? this.round(newValue) : null;
    this.updateConclusionValeurVenteForceeFourchettesArrondies();
  }

  @Watch('rapport.conclusionValeurVenaleApresTravaux')
  public conclusionValeurVenaleApresTravauxChanged(): void {
    this.updateConclusionValeurVenaleApresTravauxFourchettesArrondies();
    this.updateConclusionValeurVenteRapide();
    this.updateConclusionValeurVenteForcee();
  }

  @Watch('rapport.evaluationValeurLocativeMensuelle')
  public evaluationValeurLocativeMensuelleChanged(): void {
    this.updateConclusionValeurLocativeAnnuelle();
  }

  @Watch('rapport.evaluationValeurLocativeChargesAnnuelles')
  public evaluationValeurLocativeChargesAnnuellesChanged(): void {
    this.updateConclusionValeurLocativeAnnuelle();
  }

  @Watch('rapport.conclusionValeurLocativeAnnuelle')
  public conclusionValeurLocativeAnnuelleChanged(): void {
    this.updateConclusionValeurLocativeAnnuelleArrondie();
    this.updateConclusionValeurLocativeAnnuelleFourchettesArrondies();
  }

  @Watch('comparaisonApresTravaux')
  public comparaisonApresTravauxChanged() {
    this.updateConclusionValeurVenaleApresTravaux();
  }

  @Watch('roundComparaisonApresTravaux')
  public roundComparaisonApresTravauxChanged() {
    this.updateConclusionValeurVenaleApresTravauxArrondie();
  }

  //#endregion

  //#region EVENTS
  public onEmitVenteRapide(value: number): void {
    if (value > this.rapport.conclusionAbattementVenteForcee) this.rapport.conclusionAbattementVenteForcee = value;
  }

  public onEmitVenteForcee(value: number) {
    if (value < this.rapport.conclusionAbattementVenteRapide) this.rapport.conclusionAbattementVenteRapide = value;
  }

  //#endregion

  //#region FUNCTIONS

  private updateValeurs(): void {
    this.updateEvaluationSurfaceOuSuperficie();

    this.updateConclusionValeurVenaleArrondie();
    this.updateConclusionValeurVenaleFourchettesArrondies();

    this.updateConclusionValeurVenaleApresTravaux();
    this.updateConclusionValeurVenaleApresTravauxArrondie();

    this.updateConclusionValeurVenteRapide();
    this.updateConclusionValeurVenteRapideFourchettesArrondies();

    this.updateConclusionValeurVenteForcee();
    this.updateConclusionValeurVenteForceeFourchettesArrondies();
    this.updateConclusionValeurVenaleApresTravauxFourchettesArrondies();

    this.updateConclusionValeurLocativeAnnuelle();
    this.updateConclusionValeurLocativeAnnuelleArrondie();
    this.updateConclusionValeurLocativeAnnuelleFourchettesArrondies();
  }

  private round(value: number, at?: number): number {
    at = at || (value < 150000 ? 1000 : 5000);
    return Math.round(value / at) * at;
  }

  public calculValeur(value: Decimal.Value, percent: Decimal.Value): number {
    const p = new Decimal(1).minus(percent);
    return new Decimal(value || 0).times(p).toNumber();
  }

  public calculValeurFourchetteArrondie(value: Decimal.Value, percent: Decimal.Value, roundAt?: number | undefined): number {
    return this.round(new Decimal(value || 0).times(percent).toNumber(), roundAt);
  }

  public updateConclusionValeurVenteRapide(): void {
    if (this.hasValeurVenteRapide) {
      var valeurReference = hasValeurApresTravaux(this.mission)
        ? this.rapport.conclusionValeurVenaleApresTravaux
        : this.rapport.conclusionValeurVenale;
      this.rapport.conclusionValeurVenteRapide = this.calculValeur(valeurReference, this.rapport.conclusionAbattementVenteRapide);
    } else {
      this.rapport.conclusionValeurVenteRapide = null;
    }
  }

  public updateConclusionValeurVenteRapideFourchettesArrondies(): void {
    if (this.hasFourchetteValeur && this.hasValeurVenteRapide) {
      this.rapport.conclusionValeurVenteRapideFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenteRapide,
        this.percentFourchetteBasse
      );
      this.rapport.conclusionValeurVenteRapideFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenteRapide,
        this.percentFourchetteHaute
      );
    } else {
      this.rapport.conclusionValeurVenteRapideFourchetteBasseArrondie = null;
      this.rapport.conclusionValeurVenteRapideFourchetteHauteArrondie = null;
    }
  }

  public updateConclusionValeurVenteForcee(): void {
    if (this.hasValeurVenteForcee) {
      var valeurReference = hasValeurApresTravaux(this.mission)
        ? this.rapport.conclusionValeurVenaleApresTravaux
        : this.rapport.conclusionValeurVenale;
      this.rapport.conclusionValeurVenteForcee = this.calculValeur(valeurReference, this.rapport.conclusionAbattementVenteForcee);
    } else {
      this.rapport.conclusionValeurVenteForcee = null;
    }
  }

  public updateConclusionValeurVenteForceeFourchettesArrondies(): void {
    if (this.hasFourchetteValeur && this.hasValeurVenteForcee) {
      this.rapport.conclusionValeurVenteForceeFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenteForcee,
        this.percentFourchetteBasse
      );
      this.rapport.conclusionValeurVenteForceeFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenteForcee,
        this.percentFourchetteHaute
      );
    } else {
      this.rapport.conclusionValeurVenteForceeFourchetteBasseArrondie = null;
      this.rapport.conclusionValeurVenteForceeFourchetteHauteArrondie = null;
    }
  }

  public updateConclusionValeurVenaleArrondie(): void {
    if (!this.hasFourchetteValeur) {
      this.rapport.conclusionValeurVenaleArrondie = this.round(this.rapport.conclusionValeurVenale);
    } else {
      this.rapport.conclusionValeurVenaleArrondie = null;
    }
  }

  public updateConclusionValeurVenaleFourchettesArrondies(): void {
    if (this.hasFourchetteValeur) {
      this.rapport.conclusionValeurVenaleFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenale,
        this.percentFourchetteBasse
      );
      this.rapport.conclusionValeurVenaleFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenale,
        this.percentFourchetteHaute
      );
    } else {
      this.rapport.conclusionValeurVenaleFourchetteBasseArrondie = null;
      this.rapport.conclusionValeurVenaleFourchetteHauteArrondie = null;
    }
  }

  public updateConclusionValeurVenaleApresTravauxFourchettesArrondies(): void {
    if (this.hasFourchetteValeur && this.hasValeurApresTravaux) {
      this.rapport.conclusionValeurVenaleApresTravauxFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenaleApresTravaux,
        this.percentFourchetteBasse
      );
      this.rapport.conclusionValeurVenaleApresTravauxFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurVenaleApresTravaux,
        this.percentFourchetteHaute
      );
    } else {
      this.rapport.conclusionValeurVenaleApresTravauxFourchetteBasseArrondie = null;
      this.rapport.conclusionValeurVenaleApresTravauxFourchetteHauteArrondie = null;
    }
  }

  public showCurrentRating(rating: number): void {
    if (rating === 0 ? this.currentSelectedRating : rating == null) {
      this.currentRating = '&nbsp;';
    } else {
      this.currentRating = this.ratings[rating] + ' de liquidité à la valeur vénale établie';
    }
  }

  public updateConclusionValeurLocativeAnnuelle(): void {
    if (this.hasValeurLocative) {
      this.rapport.conclusionValeurLocativeAnnuelle = new Decimal(this.rapport.evaluationValeurLocativeMensuelle || 0)
        .times(12)
        .plus(this.rapport.evaluationValeurLocativeChargesAnnuelles || 0)
        .toNumber();
    } else {
      this.rapport.conclusionValeurLocativeAnnuelle = null;
    }
  }

  public updateConclusionValeurLocativeAnnuelleArrondie(): void {
    if (this.hasValeurLocative && !this.hasFourchetteValeur) {
      this.rapport.conclusionValeurLocativeAnnuelleArrondie = this.round(
        this.rapport.conclusionValeurLocativeAnnuelle,
        this.valeurLocativeRoundAt
      );
    } else {
      this.rapport.conclusionValeurLocativeAnnuelleArrondie = null;
    }
  }

  public updateConclusionValeurLocativeAnnuelleFourchettesArrondies(): void {
    if (this.hasValeurLocative && this.hasFourchetteValeur) {
      this.rapport.conclusionValeurLocativeAnnuelleFourchetteBasseArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurLocativeAnnuelle,
        this.percentFourchetteBasse,
        this.valeurLocativeRoundAt
      );
      this.rapport.conclusionValeurLocativeAnnuelleFourchetteHauteArrondie = this.calculValeurFourchetteArrondie(
        this.rapport.conclusionValeurLocativeAnnuelle,
        this.percentFourchetteHaute,
        this.valeurLocativeRoundAt
      );
    } else {
      this.rapport.conclusionValeurLocativeAnnuelleFourchetteBasseArrondie = null;
      this.rapport.conclusionValeurLocativeAnnuelleFourchetteHauteArrondie = null;
    }
  }

  public updateEvaluationSurfaceOuSuperficie(): void {
    if (!this.rapport.evaluationSurfaceOuSuperficie) {
      if (this.isTypeDeBienTerrain) {
        this.rapport.evaluationSurfaceOuSuperficie = this.rapport.terrainSuperficie;
      } else {
        this.rapport.evaluationSurfaceOuSuperficie = this.rapport.particuliereSurfaceTotaleHabitable;
      }
    }
  }

  public updateConclusionValeurVenaleApresTravaux(): void {
    this.rapport.conclusionValeurVenaleApresTravaux = this.hasValeurApresTravaux ? this.comparaisonApresTravaux : null;
  }

  public updateConclusionValeurVenaleApresTravauxArrondie(): void {
    if (this.hasValeurApresTravaux && !this.hasFourchetteValeur) {
      this.rapport.conclusionValeurVenaleApresTravauxArrondie = this.roundComparaisonApresTravaux;
    } else {
      this.rapport.conclusionValeurVenaleApresTravauxArrondie = null;
    }
  }

  //#endregion
}
