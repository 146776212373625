













































import { Component, Mixins, Watch } from 'vue-property-decorator';
import ValeurSpotPlusSectionMixin from '@/mixins/valeur-spot-plus-section.mixin';

import PrestationExpertiseContexteIntervention from '@/shared/components/prestation-expertise/prestation-expertise-contexte-intervention.vue';
import DateVisite from '@/shared/components/date-visite.vue';

@Component({
  name: 'PrestationExpertiseValeurSpotPlusContratMission',
  components: {
    PrestationExpertiseContexteIntervention,
    DateVisite,
  },
})
export default class PrestationExpertiseValeurSpotPlusContratMission extends Mixins(ValeurSpotPlusSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  @Watch('valeurSpotPlus.contratCategorieDActifId')
  public contratCategorieDActifIdChanged(): void {
    this.valeurSpotPlus.contratTypeBienId = null;
  }
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
