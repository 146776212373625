import Vue from 'vue';
import { MissionDto } from '@/shared/dtos/mission.dto';
import axios from 'axios';
import { isMobile } from 'mobile-device-detect';
import { SocieteSettings } from '@/shared/models/societe-settings.model';

export const getMissionByIdResolver = async (to: any, from: any, next: any) => {
  // If come from Omere, we need to authenticate the user and then retrieve the mission from the api
  if (to.query && to.query.token) {
    const logMessage = 'GET mission and données techniques';
    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'getMissionByIdResolver');

    await Vue.prototype.$externalAuthenticationService
      .loginInToOmereAndRedirectToMission(
        to.query.token,
        to.query.missionId ?? to.query.missionid,
        to.query.username,
        to.query.nonce,
        to.query.timestamp
      )
      .then(async () => {
        await getMissionAndDonneesTechniques(to, from, next);
        next();
      });
  }
  // Check before the storage
  else {
    const mission: MissionDto = await Vue.prototype.$missionStoreService.getByIndex('id', +to.params.omereMissionId);

    if (mission && mission.prestationExpertise && isMobile) {
      // Par mesure de sécurité, vérifier si le type de prestation est identique
      // sinon rediriger vers le bon lien pour éviter qu'on saisisse le mauvais rapport
      const pathToMatch = `/missions/${mission.id}/${mission.typePrestationExpertiseString}`;
      if (to.fullPath !== pathToMatch) {
        next({ path: pathToMatch, replace: true });
      }

      to.meta.mission = mission;
      to.meta.societeSettings = await Vue.prototype.$societeSettingsStoreService.getByIndex('societeId', mission.societeId);

      next();
    }
    // If the storage doesn't match or if we are on desktop, then retrieve the mission from the api and set it in the storage
    else {
      await getMissionAndDonneesTechniques(to, from, next);
    }
  }
};

async function getMissionAndDonneesTechniques(to: any, from: any, next: any): Promise<void> {
  const logMessage = 'GET mission and données techniques';
  await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'getMissionByIdResolver');

  await axios
    .all([
      Vue.prototype.$missionApiService.get(to.params.omereMissionId).then(async (missionResponse: MissionDto) => {
        if (!missionResponse) {
          return Promise.reject();
        }

        // Par mesure de sécurité, vérifier si le type de prestation est identique
        // sinon rediriger vers le bon lien pour éviter qu'on saisisse le mauvais rapport
        const pathToMatch = `/missions/${missionResponse.id}/${missionResponse.typePrestationExpertiseString}`;
        if (to.fullPath !== pathToMatch) {
          next({
            path: pathToMatch,
            replace: true,
          });
        }

        await Vue.prototype.$missionStoreService.addOrUpdate(missionResponse);

        await Vue.prototype.$permissionService.getSocieteSettings(missionResponse.societeId).then(async (settings: SocieteSettings) => {
          if (!settings) return;

          await Vue.prototype.$societeSettingsStoreService.addOrUpdate(settings);
          to.meta.societeSettings = settings;
        });

        return Promise.resolve(missionResponse);
      }),

      Vue.prototype.$synchronizationService.getDonneesTechniques(),
    ])
    .then(
      axios.spread(async (missionResponse) => {
        to.meta.mission = missionResponse;
        next();
      })
    )
    .catch(async (error: any) => {
      await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'getMissionByIdResolver');
    });
}
