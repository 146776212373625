










































import { MissionDto } from '@/shared/dtos/mission.dto';
import { EtapeMissionEnum } from '@/shared/enums/etape-mission.enum';
import { getNatureVisiteEnumName } from '@/shared/utilities/enums.utility';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CardMission',
})
export default class CardMission extends Vue {
  @Prop() public mission: MissionDto;
  @Prop() public showTime: boolean;
  @Prop() public lastMission: MissionDto;

  public etapeMissionEnum = EtapeMissionEnum;

  //#region LIFE CYCLES
  //#endregion

  //#region COMPUTED

  get natureVisiteLibelle() {
    return getNatureVisiteEnumName(this.mission.natureVisite);
  }

  get timeBetweenTwoVisites() {
    if (this.lastMission === undefined) return null;

    // 30 minutes = 1800000 ms, la visite dure 30 minutes
    var lastDateVisite = moment(this.lastMission.dateVisite).utc().valueOf() + 1800000;
    var nextDateVisite = moment(this.mission.dateVisite).utc().valueOf();

    var timeBetweenTwoVisites = nextDateVisite - lastDateVisite;

    return moment(timeBetweenTwoVisites).utc().format('HH[h]mm');
  }

  get hasMissionCompleted() {
    return this.mission.etape === EtapeMissionEnum.ExpertiseVerrouillee || this.mission.etape === EtapeMissionEnum.Validee;
  }

  get hasEmailsCommandes() {
    return this.mission.dateVisite === null && this.mission.emailsCommandes.length > 0;
  }

  //#endregion

  //#region EVENTS
  public onDisplayPrestationExpertiseClick(): void {
    this.$router.push({ path: `/missions/${this.mission.id}/${this.mission.typePrestationExpertiseString}`, replace: true });
  }

  //#endregion

  //#region FUNCTIONS
  //#endregion
}
