
































































import { Component, Inject, Mixins, Prop, Vue } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import PrestationExpertisePieceJointe from '@/shared/components/prestation-expertise/prestation-expertise-piece-jointe.vue';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import VueGallery from 'vue-gallery';
import { contratTypePrestationColorDefault } from '@/helpers/rapport-expertise.helper';
import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import _ from 'lodash';
import imageCompression from 'browser-image-compression';

@Component({
  name: 'PrestationExpertiseFileUploader',
  components: {
    PrestationExpertisePieceJointe,
    Spinner,
    VueGallery,
  },
})
export default class PrestationExpertiseFileUploader extends Mixins(DeviceMixin) {
  @Inject('addPieceJointe') public addPieceJointe: any;
  @Inject('updateOrdrePiecesJointes') public updateOrdrePiecesJointes: any;

  @Prop({ required: true }) public id: string;
  @Prop({ required: true }) public type: string;
  @Prop() public piecesJointes: { [index: string]: PrestationExpertisePieceJointeDto[] };
  @Prop() public pieceJointeId: number;
  @Prop() public workingById: boolean;
  @Prop({ default: true }) public multiple: boolean;
  @Prop({ default: true }) public showLabel: boolean;
  @Prop({ default: true }) public animate: boolean;
  @Prop({ default: true }) public state: boolean;
  @Prop() public title: string;

  public isUploading: boolean = false;
  public index: number = null;
  public contratTypePrestationColorDefault: string = contratTypePrestationColorDefault;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get canUpload(): boolean {
    return this.multiple || (!this.multiple && this.piecesJointes != null && this.filteredPiecesJointes.length == 0);
  }

  get filteredPiecesJointes(): PrestationExpertisePieceJointeDto[] {
    let filteredPiecesJointes: PrestationExpertisePieceJointeDto[] = [];

    if (this.workingById) {
      filteredPiecesJointes = this.piecesJointes[this.type].filter((p: any) => p.id == this.pieceJointeId);
    } else filteredPiecesJointes = this.piecesJointes[this.type] || [];

    return _.orderBy(
      filteredPiecesJointes.filter((pieceJointe: PrestationExpertisePieceJointeDto) => !pieceJointe.isDeleted),
      'ordre',
      'asc'
    );
  }

  get images(): string[] {
    return this.filteredPiecesJointes.map((pj: any) => {
      if (pj.content.indexOf('base64') === -1) {
        return `${Vue.prototype.$config.baseUrlApiAppService}${pj.content}`;
      }
      return pj.content;
    });
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  /**
   * Attention, cette méthode prépare l'upload mais ne le déclenche pas !
   * La méthode en charge de l'upload se trouve dans "synchronization.service.ts" : managePiecesJointes()
   */
  private async prepareFileUpload(files: any[]): Promise<void> {
    const nbFiles = files.length;
    let nbFileProcessed = 0;

    if (!nbFiles) return;

    // https://www.npmjs.com/package/browser-image-compression
    const options = {
      maxSizeMB: 0.25, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 1024, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
      // Please check the Caveat part for details.

      initialQuality: 1, // optional, initial quality value between 0 and 1 (default: 1)
    };

    for (let file of files) {
      if (!'jpg|jpeg|png'.includes(file.name.toLowerCase().split('.').pop())) {
        this.isUploading = false;
        Vue.prototype.$notificationService.warn(`Le fichier ${file.name} n'est pas une image valide (.jpg,.jpeg,.png)`);
      } else {
        this.isUploading = true;
        // console.log('---------');
        // console.log('originalFile instanceof Blob', file instanceof Blob); // true
        // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
        file = await imageCompression(file, options);
        // console.log('compressedFile instanceof Blob', file instanceof Blob); // true
        // console.log(`compressedFile size ${file.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        const base64Content = await imageCompression.getDataUrlFromFile(file);

        this.addPieceJointe(this.type, base64Content, file.name).then((id: string) => {
          if (id) this.$emit('uploadedFileId', id);

          nbFileProcessed++;

          if (nbFileProcessed >= nbFiles) {
            this.isUploading = false;
          }
        });
      }
    }
  }

  public async handleFileUpload(e: any): Promise<void> {
    this.prepareFileUpload(e.target.files);
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
