









































import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import RadioBoolean from '@/shared/components/radio-boolean.vue';
import SimpleChart from '@/shared/components/simple-chart.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import VisaDisplay from '@/shared/components/visa-display.vue';
import { DonneesEstimationImmobiliereDto } from '@/shared/dtos/donnees-estimation-immobiliere.dto';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { SubjectMessage } from '@/shared/models/subject-message';

@Component({
  name: 'PrestationExpertiseRapportSituationFiscaleFiscalite',
  components: {
    RadioBoolean,
    SimpleChart,
    NumericNullable,
    ChoicePopup,
    VisaDisplay,
  },
})
export default class PrestationExpertiseRapportSituationFiscaleFiscalite extends Mixins(RapportExpertiseSectionMixin) {
  public donneesEstimationImmobiliere: DonneesEstimationImmobiliereDto = null;
  public charts: any = null;

  //#region LIFE CYCLES
  public beforeMount(): void {
    Vue.prototype.$subjectMessageService.subject.subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.REFRESH_DONNEES_ESTIMATION_IMMOBILIERES) {
        this.donneesEstimationImmobiliere = subjectMessage.message;
        if (this.donneesEstimationImmobiliere) {
          this.charts = this.donneesEstimationImmobiliere.charts;
        }
      }
    });
  }

  //#endregion

  //#region WATCH

  @Watch('rapport.fiscaleTaxeFonciere')
  public fiscaleTaxeFonciereChanged(val: any): void {
    if (val !== true) {
      this.rapport.fiscaleSourceTaxeFonciereId = null;
      this.rapport.fiscaleMontantTaxeFonciere = null;
    }
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS

  getTaxeHabitationOptions() {
    if (!this.charts) return null;
    return (window as any).getChartTaxeHabitationOptions(this.charts.taxeHabitation);
  }
  getTaxeFonciereOptions() {
    if (!this.charts) return null;
    return (window as any).getChartTaxeFonciereOptions(this.charts.taxeFonciere);
  }

  //#endregion
}
