

















































































import { Vue, Component, Mixins } from 'vue-property-decorator';

import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import SimpleChart from '@/shared/components/simple-chart.vue';
import MultipleChart from '@/shared/components/multiple-chart.vue';
import ExpCommodite from '@/components/exp-commodite.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import VisaDisplay from '@/shared/components/visa-display.vue';
import PrestationExpertiseSituationAdresse from '@/shared/components/prestation-expertise/prestation-expertise-situation-adresse.vue';
import PrestationExpertiseRapportSituationGeographiqueAvisExpert from './prestation-expertise-rapport-situation-geographique-avis-expert.vue';
import { TypeDonneesEstimationImmobiliereEnum } from '@/shared/enums/donnees-estimation-immobiliere-type.enum';
import { DonneesEstimationImmobiliereDto } from '@/shared/dtos/donnees-estimation-immobiliere.dto';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { SubjectMessage } from '@/shared/models/subject-message';
import Radios from '@/shared/components/radios.vue';

@Component({
  name: 'PrestationExpertiseRapportSituationGeographiqueGeographie',
  components: {
    SimpleChart,
    MultipleChart,
    ExpCommodite,
    ChoicePopup,
    VisaDisplay,
    PrestationExpertiseSituationAdresse,
    PrestationExpertiseRapportSituationGeographiqueAvisExpert,
    Radios,
  },
})
export default class PrestationExpertiseRapportSituationGeographiqueGeographie extends Mixins(RapportExpertiseSectionMixin) {
  public donneesEstimationImmobiliere: DonneesEstimationImmobiliereDto = null;
  public charts: any = null;
  public showSituationGeographiqueGraphiques: boolean = false;

  //#region LIFE CYCLES
  public beforeMount(): void {
    Vue.prototype.$subjectMessageService.subject.subscribe((subjectMessage: SubjectMessage) => {
      if (subjectMessage.type === SubjectMessageTypeEnum.REFRESH_DONNEES_ESTIMATION_IMMOBILIERES) {
        this.donneesEstimationImmobiliere = subjectMessage.message;
        if (this.donneesEstimationImmobiliere) {
          this.charts = this.donneesEstimationImmobiliere.charts;
          this.showSituationGeographiqueGraphiques = this.donneesEstimationImmobiliere.type === TypeDonneesEstimationImmobiliereEnum.OuiV1;

          if (!this.rapport.geographiqueCommodites) {
            this.rapport.geographiqueCommodites = this.donneesEstimationImmobiliere.commodites.allCommodites;
          }
        }
      }
    });
  }

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS
  public getTauxChomageOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartTauxChomageOptions(this.charts.tauxChomage);
  }

  public getDensitePopulationOptions(): any {
    if (!this.charts) return null;
    return (window as any).getChartDensitePopulationOptions(this.charts.densitePopulation);
  }

  public getRepartitionAgeOptions(dataset: any): any {
    if (!this.charts) return null;
    return (window as any).getChartRepartitionAgeOptions(dataset);
  }

  public getEffectifsPopulationOptions(dataset: any): any {
    if (!this.charts) return null;
    return (window as any).getChartEffectifsPopulationOptions(dataset);
  }

  //#endregion
}
