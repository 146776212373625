








































































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import Radios from '@/shared/components/radios.vue';
import { required } from 'vuelidate/lib/validators';
import { PrestationCuisineDto } from '@/shared/dtos/prestation-cuisine.dto';

@Component({
  name: 'ExpCuisine',
  components: { RapportExpertiseFileUploader, Radios },
  validations: {
    newLabel: { required },
  },
})
export default class ExpCuisine extends Vue {
  @Inject('onDeleteCuisineClick') public onDeleteCuisineClick: any;

  @Prop() public value: PrestationCuisineDto;
  @Prop() public index: number;
  @Prop() public piecesJointes: any;
  @Prop() public businessEntries: any;
  @Prop() public validation: any;

  public isEditing: boolean = false;
  public newLabel: string = null;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  get cuisine() {
    return this.value;
  }

  get gammes() {
    return this.businessEntries.prestationsCuisinesGammes.filter((g: any) => g.parentId == this.value.styleId);
  }

  //#endregion

  //#region WATCH

  @Watch('value.styleId')
  public styleIdChanged(): void {
    this.value.gammeId = null;
  }

  @Watch('piecesJointes.cuisine')
  public cuisineChanged(newValue: any): void {
    if (!newValue.some((pj: any) => pj.id == this.value.photoId)) this.value.photoId = null; // gère le cas où on supprime la photo uniquement
  }

  //#endregion

  //#region EVENTS
  onEditClick() {
    this.isEditing = true;
    this.newLabel = this.value.label;
    this.$nextTick(() => {
      let el = (this.$refs.inputNewLabel as any).$el;
      el.focus();
      el.setSelectionRange(el.value.length, el.value.length);
    });
  }

  onUpdateClick() {
    if (this.$v.newLabel.$invalid) return;
    this.isEditing = false;
    this.value.label = this.newLabel;
  }

  onCancelClick() {
    this.isEditing = false;
  }

  onUploadedFileId(id: number) {
    if (id != null && id !== 0) this.value.photoId = id;
  }
  //#endregion

  //#region FUNCTIONS

  //#endregion
}
