/* eslint-disable no-console */

import { BaseStoreService } from './base-store.service';
import { IDBPDatabase } from 'idb';
import { SocieteSettings } from '@/shared/models/societe-settings.model';

export class SocieteSettingsStoreService extends BaseStoreService<SocieteSettings> {
  constructor(db: IDBPDatabase) {
    super('societe-settings', 'societeId', db);
  }
}
