import { TypeBusinessEntryEnum } from '../enums/type-business-entry.enum';
import { TypeCategorieActifEnum } from '../enums/type-categorie-actif.enum';

export class RapportExpertiseBusinessEntryDto {
  id: number;
  displayText: string;
  orderIndex: number;
  isActive: boolean;
  iconName: string;
  parentId?: number;
  categorieActifType: TypeCategorieActifEnum;
  abreviated: string;
  class: string;
  type: TypeBusinessEntryEnum;
  iconRepetition: number;

  minVersion: number;
  maxVersion: number;

  constructor(id: number, displayText: string, parentId?: number) {
    this.id = id;
    this.displayText = displayText;
    this.parentId = parentId;
  }
}
