


















































import { Component, Mixins, Watch } from 'vue-property-decorator';

import PrestationExpertiseContexteIntervention from '@/shared/components/prestation-expertise/prestation-expertise-contexte-intervention.vue';

import EtatAvancementSectionMixin from '@/mixins/etat-avancement-travaux-section.mixin';
import DateVisite from '@/shared/components/date-visite.vue';

@Component({
  name: 'PrestationExpertiseEtatAvancementTravauxContratMission',
  components: {
    PrestationExpertiseContexteIntervention,
    DateVisite,
  },
})
export default class PrestationExpertiseEtatAvancementTravauxContratMission extends Mixins(EtatAvancementSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED
  //#endregion
  //#region WATCH
  @Watch('etatAvancementTravaux.contratCategorieDActifId')
  public contratCategorieDActifIdChanged(): void {
    this.etatAvancementTravaux.contratTypeBienId = null;
  }
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
