























































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaDisplay from '@/shared/components/visa-display.vue';
import VisaNote from '@/shared/components/visa-note.vue';
import DatePicker from '@/shared/components/date-picker.vue';
import ExtendableListString from '@/shared/components/extendable-list-string.vue';
import TextareaWithDefaultValue from '@/shared/components/textarea-with-default-value.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import TableRadios from '@/shared/components/table-radios.vue';

const JuridiqueModeDetentionCoproprieteIds = [2013003, 2013004];

@Component({
  name: 'PrestationExpertiseRapportSituationJuridiqueJuridique',
  components: {
    VisaNote,
    VisaDisplay,
    DatePicker,
    ExtendableListString,
    TextareaWithDefaultValue,
    ChoicePopup,
    TableRadios,
  },
})
export default class PrestationExpertiseRapportSituationJuridiqueJuridique extends Mixins(RapportExpertiseSectionMixin) {
  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED
  get isModeDetentionCopropriete(): boolean {
    return this.rapport.juridiqueModesDeDetentions.some(
      (m: any) => JuridiqueModeDetentionCoproprieteIds.indexOf(m.businessEntrySelectedId) != -1
    );
  }

  //#endregion

  //#region WATCH
  @Watch('rapport.juridiqueDateAcquisition')
  public onJuridiqueDateAcquisitionChanged(value: any): void {
    if (value !== null) {
      this.rapport.juridiqueDateAcquisitionInconnue = false;
    }
  }

  @Watch('rapport.juridiquePrixAcquisition')
  public onJuridiquePrixAcquisitionChanged(value: any): void {
    if (value !== null) {
      this.rapport.juridiquePrixAcquisitionInconnue = false;
    }
  }

  //#endregion

  //#region EVENTS
  public onJuridiqueDateAcquisitionInconnueClick(): void {
    this.rapport.juridiqueDateAcquisition = new Date(); // To trigger the validation
    this.rapport.juridiqueDateAcquisition = null;
    this.rapport.juridiqueDateAcquisitionInconnue = true;
  }

  public onJuridiquePrixAcquisitionInconnueClick(): void {
    this.rapport.juridiquePrixAcquisition = null;
    this.rapport.juridiquePrixAcquisitionInconnue = true;
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
