


















































































































































































import { Component, Inject, Mixins, Provide, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import RadioBoolean from '@/shared/components/radio-boolean.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import ExpCuisine from '@/components/exp-cuisine.vue';
import ExpSalleDeBain from '@/components/exp-salle-de-bain.vue';
import {
  isContratTypeVisiteInterieurEtExterieur,
  isContratTypeVisiteExterieur,
  isContratTypeVisiteSurPieces,
} from '@/helpers/rapport-expertise.helper';
import { RapportExpertiseBusinessEntryQualifiableDto } from '@/shared/dtos/rapport-expertise-business-entry-qualifiable.dto';

@Component({
  name: 'PrestationExpertiseRapportDescriptionParticulierePrestations',
  components: {
    RadioBoolean,
    ChoicePopup,
    ExpCuisine,
    ExpSalleDeBain,
  },
})
export default class PrestationExpertiseRapportDescriptionParticulierePrestations extends Mixins(RapportExpertiseSectionMixin) {
  @Inject('deletePieceJointeByIdAndType') public deletePieceJointeByIdAndType: any;
  @Inject('confirmDelete') public confirmDelete: any;

  @Provide('onDeleteCuisineClick') public deleteCuisineProvide: any = this.onDeleteCuisineClick;
  @Provide('onDeleteSalleDeBainClick') public deleteSalleDeBainProvide: any = this.onDeleteSalleDeBainClick;

  //#region LIFE CYCLES
  //#endregion

  //#region COMPUTED

  get prestationsModesChauffages(): RapportExpertiseBusinessEntryQualifiableDto[] {
    return this.businessEntries.prestationsModesChauffages;
  }

  get equipements(): any {
    return this.businessEntries.prestationsEquipements.filter(
      (e: any) => e.categorieActifType === 0 || e.categorieActifType === this.typeDeBien
    );
  }

  get agrements(): any {
    return this.businessEntries.prestationsAgrements.filter(
      (e: any) => e.categorieActifType === 0 || e.categorieActifType === this.typeDeBien
    );
  }

  get isContratTypeVisiteInterieurEtExterieur(): boolean {
    return isContratTypeVisiteInterieurEtExterieur(this.rapport.contratTypeVisiteId);
  }

  get isContratTypeVisiteExterieur(): boolean {
    return isContratTypeVisiteExterieur(this.rapport.contratTypeVisiteId);
  }

  get isContratTypeVisiteSurPieces(): boolean {
    return isContratTypeVisiteSurPieces(this.rapport.contratTypeVisiteId);
  }

  //#endregion

  //#region WATCH
  @Watch('rapport.prestationAvecChauffage')
  public prestationAvecChauffageChanged(value: boolean) {
    if (value === false) this.rapport.prestationsModesChauffages = [];
  }

  //#endregion

  //#region EVENTS
  public onAddCuisineClick(): void {
    this.rapport.prestationsCuisines.push({
      id: 0,
      label: 'Cuisine',
      photoId: null,
      styleId: null,
      gammeId: null,
      equipementId: null,
      rapportExpertiseId: +this.rapport.id,
    });
  }

  public onDeleteCuisineClick(index: number): any {
    let cuisine = this.rapport.prestationsCuisines[index];
    let msg = cuisine.photoId != null ? ' et sa photo associée' : '';

    return this.confirmDelete(`cette cuisine${msg}`).then(() => {
      this.rapport.prestationsCuisines.splice(index, 1);
      if (cuisine.photoId != null) {
        this.deletePieceJointeByIdAndType(cuisine.photoId, 'Cuisine');
      }
    });
  }

  public onAddSalleDeBainClick(): void {
    this.rapport.prestationsSallesDeBains.push({
      id: 0,
      label: 'Salle de bains',
      photoId: null,
      gammeId: null,
      etatId: null,
      rapportExpertiseId: +this.rapport.id,
    });
  }

  public onAddSalleDEauClick(): void {
    this.rapport.prestationsSallesDeBains.push({
      id: 0,
      label: "Salle d'eau",
      photoId: null,
      gammeId: null,
      etatId: null,
      rapportExpertiseId: +this.rapport.id,
    });
  }

  public onDeleteSalleDeBainClick(index: number): any {
    let sdb = this.rapport.prestationsSallesDeBains[index];
    let msg = sdb.photoId != null ? ' et sa photo associée' : '';

    return this.confirmDelete(`cette salle de bains / d'eau${msg}`).then(() => {
      this.rapport.prestationsSallesDeBains.splice(index, 1);
      if (sdb.photoId != null) {
        this.deletePieceJointeByIdAndType(sdb.photoId, 'SalleDeBain');
      }
    });
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
