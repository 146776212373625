















































import { Component, Mixins, Watch } from 'vue-property-decorator';

import EtudeDeMarcheSectionMixin from '@/mixins/etude-de-marche-section.mixin';

import PrestationExpertiseContexteIntervention from '@/shared/components/prestation-expertise/prestation-expertise-contexte-intervention.vue';
import DatePicker from '@/shared/components/date-picker.vue';
import { getTypeValeurEnumToDictionary } from '@/shared/utilities/enums.utility';
@Component({
  name: 'PrestationExpertiseEtudeDeMarcheContratMission',
  components: {
    PrestationExpertiseContexteIntervention,
    DatePicker,
  },
})
export default class PrestationExpertiseEtudeDeMarcheContratMission extends Mixins(EtudeDeMarcheSectionMixin) {
  //#region LIFE CYCLES
  //#endregion
  //#region COMPUTED

  get typeValeurString() {
    let typeValeurString = '';

    this.mission.typesValeurs.forEach((item) => {
      typeValeurString += `${getTypeValeurEnumToDictionary(item)}, `;
    });

    return typeValeurString.substring(0, typeValeurString.length - 2);
  }

  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS
  //#endregion
  //#region FUNCTIONS
  //#endregion
}
