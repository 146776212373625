













































































































































































































































































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';

import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import DatePicker from '@/shared/components/date-picker.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import RadioBoolean from '@/shared/components/radio-boolean.vue';
import VisaDisplay from '@/shared/components/visa-display.vue';
import { ToggleButton } from 'vue-js-toggle-button';

@Component({
  name: 'PrestationExpertiseRapportSituationUrbanistiqueUrbanisme',
  components: {
    RadioBoolean,
    DatePicker,
    ChoicePopup,
    VisaDisplay,
    ToggleButton,
  },
})
export default class PrestationExpertiseRapportSituationUrbanistiqueUrbanisme extends Mixins(RapportExpertiseSectionMixin) {
  public iconsUrl: string = Vue.prototype.$config.baseUrlApi + 'images/icons/';

  //#region LIFE CYCLES
  //#endregion
  //#region WATCH

  @Watch('rapport.urbanistiquePlanLocalUrbanismeDisponibilite')
  public urbanistiquePlanLocalUrbanismeDisponibiliteChanged(val: any): void {
    if (val !== true) {
      this.rapport.urbanistiquePlanLocalUrbanismeSelectionneId = null;
      this.rapport.urbanistiqueZone = null;
      this.rapport.urbanistiqueZoneCommentaire = null;
      this.rapport.urbanistiqueDateApprobation = null;
    }
  }

  @Watch('rapport.urbanistiqueInondable')
  public urbanistiqueInondableChanged(val: boolean): void {
    if (val !== true) {
      this.rapport.urbanistiqueInondationId = null;
    }
  }

  @Watch('rapport.urbanistiqueSeisme')
  public urbanistiqueSeismeChanged(val: boolean): void {
    if (val !== true) {
      this.rapport.urbanistiqueSeismeSelectionneId = null;
    }
  }

  @Watch('rapport.urbanistiqueMouvementDeTerrain')
  public urbanistiqueMouvementDeTerrainChanged(val: boolean): void {
    if (val !== true) {
      this.rapport.urbanistiqueMouvementDeTerrains = [];
    }
  }

  @Watch('rapport.urbanistiqueCaviteSouterraine')
  public urbanistiqueCaviteSouterraineChanged(val: boolean): void {
    if (val !== true) {
      this.rapport.urbanistiqueCaviteSouterraines = [];
    }
  }
  @Watch('rapport.urbanistiqueGonflementDesSolsArgileux')
  public urbanistiqueGonflementDesSolsArgileuxChanged(val: boolean): void {
    if (val !== true) {
      this.rapport.urbanistiqueGonflementDesSolsArgileuxSelectionneId = null;
    }
  }

  @Watch('rapport.urbanistiqueInstallationIndustrielle')
  public urbanistiqueInstallationIndustrielleChanged(val: boolean): void {
    if (val !== true) {
      this.rapport.urbanistiqueInstallationIndustrielles = [];
    }
  }

  //#endregion

  //#region EVENTS

  //#endregion

  //#region FUNCTIONS
  public goToGeorisques() {
    if (this.rapport.geographiqueLongitude && this.rapport.geographiqueLatitude) {
      const href = Vue.prototype.$config.georisquesBaseRapportUrl
        .replace('{0}', this.rapport.geographiqueLongitude)
        .replace('{1}', this.rapport.geographiqueLatitude)
        .replace('{2}', 'false');

      window.open(href, '_blank');
    } else {
      window.open(Vue.prototype.$config.georisquesBaseUrl, '_blank');
    }
  }

  public onChangeShowSectionRisques(srcEvent: any) {
    if (srcEvent.value) {
      this.rapport.urbanistiqueInondable = null;
      this.rapport.urbanistiqueSeisme = null;
      this.rapport.urbanistiqueMouvementDeTerrain = null;
      this.rapport.urbanistiqueCaviteSouterraine = null;
      this.rapport.urbanistiqueGonflementDesSolsArgileux = null;
      this.rapport.urbanistiqueInstallationIndustrielle = null;
      this.rapport.urbanistiqueSeismeSelectionneId = null;
    }
  }
  //#endregion
}
